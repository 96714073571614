<script lang="ts" setup>
withDefaults(
	defineProps<{
		type?: "slider_vertical" | "slider_horizontal";
		sectionName?: string;
		row?: number;
		title: string;
		showAll?: boolean;
		icon?: string;
		isAdminIcon?: boolean;
	}>(),
	{
		sectionName: "",
		showAll: false,
		row: 1
	}
);

defineEmits<{ (e: "viewAllAction"): void }>();

const { t } = useT();
</script>

<template>
	<div class="list-items-wrapper">
		<MHomeSectionHeader
			:title="t(title)"
			:icon="icon || ''"
			:isAdminIcon="isAdminIcon"
			:showAll="showAll"
			@view-all-action="$emit('viewAllAction')"
		/>
		<AHorizontalScroll shadowEffect>
			<div :class="['box-items', sectionName]">
				<slot />
			</div>
		</AHorizontalScroll>
	</div>
</template>

<style lang="scss" scoped>
div.list-items-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		gap: 16px;
	}
}

.box-items {
	display: grid;
	grid-auto-flow: column dense;
	gap: 12px;
	grid-template-rows: repeat(v-bind(row), 1fr);

	@include media-breakpoint-up(md) {
		gap: 16px;
	}

	&:deep(img) {
		pointer-events: none;
	}
}

@include media-breakpoint-down(md) {
	div.scroll-container {
		width: calc(100% + 32px);
		margin-left: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}
}
</style>
